import Layout from "src/components/ui/Layout";
import Container from "src/components/ui/Container";
import Card from "src/components/ui/Card";
import { useRouter } from "next/router";

import LoginFlow from "./LoginFlow";

export default function LoginPage() {
  const router = useRouter();
  function onSuccess() {
    router.push("/");
  }
  return (
    <Layout>
      <Container>
        <div className="max-w-2xl mx-auto py-20">
          <Card>
            <div className="p-2 md:p-6">
              <LoginFlow onSuccess={onSuccess} />
            </div>
          </Card>
        </div>
      </Container>
    </Layout>
  );
}
